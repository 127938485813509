.sidebarWrapper{
    @apply lg:block absolute flex flex-col left-0 top-0 md:static md:left-auto md:top-auto h-screen overflow-y-scroll md:overflow-y-auto w-full md:w-64 shrink-0 transition-all duration-200 ease-in-out z-40 bg-white;
}
.navWrapper{
    @apply flex flex-col h-full overflow-y-scroll;
}

.navWrapper::-webkit-scrollbar {
    display: none;
}

.navWrapper::-webkit-scrollbar-track {
    border-radius: 10px;
}

.navWrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

#menu li a.active, li.active {
    @apply text-green-900 bg-green-200 rounded-md;
    border-radius: 0.375rem !important;
    color: rgb(20 83 45 / var(--tw-text-opacity)) !important;
}

#menu ul > li > a.link{
    @apply font-normal;
    position: relative;
}

#menu ul > li > a.link:last-child:before {
    height: 1em;
    bottom: auto;
}

#menu ul > li > a.link:before{
    position: absolute;
    top: 0;
    bottom: 0;
    left: -0.5em;
    display: block;
    width: 0;
    border-left: 1px dashed #777;
    content: "";
}
#menu ul > li > a.link:after{
    position: absolute;
    top: 1em;
    left: -0.5em;
    display: block;
    height: 0.5em;
    width: 1em;
    border-bottom: 1px dashed #777;
    border-left: 1px dashed #777;
    border-radius: 0 0 0 0.3em;
    content: '';
}

#menu ul > li > a.active {
    @apply bg-transparent font-semibold;
}

li > a > svg {
    @apply w-4 h-4 group-hover:text-gray-400
}

li > a.active > svg, li.active > svg {
    @apply text-green-700;
}

#menu .link, button.link {
    @apply flex items-center px-4 py-2 text-sm text-slate-500 font-semibold rounded-sm gap-6 cursor-pointer;
}

#menu .link:hover{
    @apply text-slate-700;
}